.map {
    height: 100%;
}
.clearButton {
    position: absolute;
    right: 0.1%;
    top: 7%;
    z-index: 999;
    margin: 11px 11px 0px 0px;
}
