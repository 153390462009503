.container {
    border-bottom: 1px #bbb solid;
    font-size: 13px;
}

.container:last-child {
    border-bottom: 0;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    align-items: center;
}

.content {
    padding: 0 16px 8px;
}

.title {
    flex-grow: 2;
    text-align: left;
    cursor: pointer;
}
