.panel {
    background: #eee;
}
.panel :global(.ant-collapse-content-box) {
    background: #d9d9d9;
    padding: 0;
    padding-top: 0 !important;
}

.collapse {
    /* background: #000; */
}
