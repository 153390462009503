.open-enter {
    transform: translateX(-24px);
    opacity: 0;
    animation-duration: 100ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-play-state: paused;
}

.open-appear {
    transform: translateX(-24px);
    opacity: 0;
    animation-duration: 100ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-play-state: paused;
}

.open-leave {
    transform: translateX(0px);
    opacity: 1;
    animation-duration: 100ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-play-state: paused;
}

.open-enter.open-enter-active {
    animation-name: openIn;
    animation-play-state: running;
}

.open-appear.open-appear-active {
    animation-name: openIn;
    animation-play-state: running;
}

.open-leave.open-leave-active {
    animation-name: openOut;
    animation-play-state: running;
}

@keyframes openIn {
    0% {
        opacity: 0;
        transform: translateX(-24px);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes openOut {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }
    100% {
        opacity: 0;
        transform: translateX(-24px);
    }
}
