.slide-enter {
    transform: translateX(100%);
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-timing-function: ease;
    animation-play-state: paused;
}

.slide-appear {
    transform: translateX(100%);
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-timing-function: ease;
    animation-play-state: paused;
}

.slide-leave {
    transform: translateX(0%);
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-timing-function: ease;
    animation-play-state: paused;
}

.slide-enter.slide-enter-active {
    animation-name: slideIn;
    animation-play-state: running;
}

.slide-appear.slide-appear-active {
    animation-name: slideIn;
    animation-play-state: running;
}

.slide-leave.slide-leave-active {
    animation-name: slideOut;
    animation-play-state: running;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
