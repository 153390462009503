.container {
    text-align: left;
}

.container label {
    display: block;
    font-size: 12px;
}

.inputGroup {
}

.inputGroup .inputSplit {
    max-width: 30px;
    pointer-events: none;
    background-color: #fff;
    border-right: 0;
    border-left: 0;
}

.inputNumberLeft {
    max-width: 70px;
    margin-right: 8px;
}

.inputNumberRight {
    max-width: 70px;
}

.inputGroup .inputNumberRight:hover,
.inputGroup .inputNumberRight:focus {
    border-left-width: 1px;
}

.errorMsg {
    font-size: 11px;
}
