@import '~antd/dist/antd.less';

:root {
    --primary-color: #143642;
    --secondary-color: #2fc2f6;
    --background-color: #eee;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/bebasneue-regular.woff2') format('woff2'),
        url('./assets/fonts/bebasneue-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: underline;
}

@primary-color: #143642;@layout-header-background: #ffffff;@layout-trigger-background: #ffffff;@layout-trigger-color: #143642;