.container {
    text-align: left;
    font-size: 12px;
}

.propertyName {
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: top;
    width: 130px;
}

.propertyValue {
    font-size: 12px;
    vertical-align: top;
}
