.container {
    border: solid 1px #d9d9d9;
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    text-align: left;
}

.strokeContainer {
    display: flex;
    align-items: center;
    font-size: 11px;
}

.stroke {
    width: 32px;
    height: 2px;
    margin-right: 8px;
}

.iconContainer {
    display: flex;
    align-items: center;
    font-size: 11px;
}

.icon {
    margin-right: 8px;
}

.icon svg {
    height: 16px;
}
