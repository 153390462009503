.container {
    position: absolute;
    z-index: 999;
    bottom: 8px;
    left: 8px;
    display: flex;
    align-items: center;
}

.optionsContainer {
    margin-left: 16px;
    background: #fff;
    border-radius: 32px;
    padding: 4px;
}

.optionsContainer > * {
    margin-right: 4px;
}

.optionsContainer > *:last-child {
    margin-right: 0;
}

.selectedOption {
    border-radius: 32px;
    padding: 4px;
    background: #fff;
    cursor: pointer;
}
