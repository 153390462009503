.container {
    flex-direction: row;
    width: 100%;
    text-align: center;
    min-height: 100vh;
}

.content {
    position: relative;
}
