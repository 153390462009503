.container {
    background: var(--background-color);
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    z-index: 999;
}

.container :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 2;
}

.footer {
    padding: 8px 0;
}

.menu {
    background: var(--background-color);
}

.logoContainer {
    display: flex;
    align-items: center;
    padding: 8px;
    background: var(--primary-color);
}

.logoContainerCollapsed {
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-items: center;
}

.menuItemCollapsed {
    padding: 0 !important;
    text-align: center;
}

.menuItemCollapsed span {
    width: 24px;
}

.logo {
    padding: 0 16px 0 8px;
    width: 100%;
    display: flex;
    font-size: 36px;
    align-items: center;
    color: var(--secondary-color);
    font-family: 'Bebas Neue';
}

.logo span {
    margin-top: 5px;
    line-height: 1;
}

.logo img {
    max-height: 30px;
    margin-right: 4px;
}

.collapseButton {
    border: none;
}

.logoContainerCollapsed .logo {
    margin-bottom: 16px;
}

.logoContainerCollapsed .logo {
    padding: 0;
    justify-content: center;
}

.logoContainerCollapsed .logo img {
    margin: 0;
}

.collapseButton svg {
    margin-top: -6px;
}
