.container {
    margin-bottom: 12px;
    background: #fff;
    border-radius: 4px;
    padding: 8px;
}

.filtersContainer {
    margin-bottom: 12px;
}

.filtersContainer:last-child {
    margin-bottom: 0;
}

.applyBtn {
    justify-self: right;
    font-size: 11px;
    font-weight: bold;
    height: 20px;
    border: 2px solid var(--primary-color);
    line-height: 16px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
