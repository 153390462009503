.lmap-cluster-icon {
    /* background: #2ab351; */
    background: #000000;
    color: #fff;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /* box-shadow: 0px 0px 4px 3px #375738; */
    box-shadow: 0px 0px 4px 3px #ffffff;
    line-height: 1;
}
.lmap-selected-cluster-icon {
    background: #b79b17;
    color: #fff;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: 0px 0px 4px 3px #6c5b24;
    line-height: 1;
}

.fontawesome-marker {
    font-size: 34px;
    stroke: black;
    stroke-width: 40;
    filter: drop-shadow(0px 0px 3px #ffffff) drop-shadow(0px 0px 3px #ffffff);
}

.lmap-selected-marker {
    background-image: url('../../assets/icons/money-marker-active.png');
    background-position: center;
    background-size: cover;
}
