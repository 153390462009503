/* Login Component */
#login {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    text-align: center;
}

#savesolar-login-logo {
    width: 80%;
    margin-bottom: 40px;
}

#login form {
    margin: auto;
    width: 92%;
}
#login form button {
    width: 40%;
}

#spinner-div {
    height: 30px;
    margin-bottom: 15px;
    color: #0a87cf;
}
