.container {
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 999;
    background: var(--background-color);
    width: 320px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-clip: padding-box;
    padding-bottom: 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.container :global(.ant-collapse-header) {
    padding: 0 !important;
}

.sectionTitle {
    background: var(--primary-color);
    color: #fff;
    padding: 8px 16px 4px;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    text-align: left;
    font-family: 'Bebas Neue';
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-color);
}

.closeBtn {
    cursor: pointer;
    font-size: 14px;
}
